import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProbillAccordionItem } from "./probill-accordion/probillAccordionItem";
import { ConsigneeAccordionItem } from "./consignee-accordion/consigneeAccordionItem";
import { stopDetailsSelector } from "../../slices/stop-details/stopDetailsSlice";
import Loading from "../../components/loading/loading";
import { StopDetailsPath } from "./routes";
import useSectionIndicator from "../../hooks/useSectionIndicator";
import { ROUTES } from "../../app/route/RoutesConfig";
import { StopStatus } from "../../app/data/common/constants";
import {
  ConsigneeDetails,
  ProbillDetails
} from "../../app/data/stop-details/models";
import { StopDetailsStickyHeader } from "./stop-sticky-header/stopStickyHeader";
import { Accordion } from "../../components/accordion/accordion";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import { SignShipment } from "./sign-shipment/signShipment";
import { routeSelectSelector } from "../../slices/route-select/routeSelectSlice";
import "./stopDetails.scss";
import "./consignee-accordion/consigneeAccordionItem.scss";
import "./probill-accordion/probillAccordionItem.scss";
import { StopDetailsHeader } from "./stop-details-header/stopDetailsHeader";
import { ShipperAccordionItem } from "./shipper-accordion/shipperAccordionItem";
import { pickupsSelector } from "../../slices/pickups/pickupsSlice";

interface PodModalState {
  show: boolean;
  consignee: ConsigneeDetails | null;
}

const initialPodModalState: PodModalState = {
  show: false,
  consignee: null,
};

const useStopSectionIndicator = () => {
  const consignee = useSectionIndicator("consignee-accordion");
  const probill = useSectionIndicator("probill-accordion-" + consignee);
  return [consignee, probill];
};

export const StopDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const stopDetailsState = useSelector(stopDetailsSelector);
  const routeSelectState = useSelector(routeSelectSelector);
  const pickupsState = useSelector(pickupsSelector);
  const [podModal, setPodModal] = useState<PodModalState>(initialPodModalState);
  const [consigneeSectionIdx, probillSectionIdx] = useStopSectionIndicator();

  const stopNumber = useMemo(() => {
    const stopParam = params[StopDetailsPath.stopNumber];
    if (stopParam) return parseInt(stopParam, 10);
    return null;
  }, [params]);

  const manifestNumber = useMemo(() => {
    return params[StopDetailsPath.manifestNumber] ? parseInt(params[StopDetailsPath.manifestNumber], 10) : 0;
  }, [params]);

  const stopNumberIndex = manifestNumber
    ? (stopDetailsState.details ? stopDetailsState.details.findIndex((stop) => stop.order === stopNumber) : -1)
    : (pickupsState.pickups ? pickupsState.pickups.findIndex((pickup) => pickup.order === stopNumber) : -1);
  const stopDetailsData = manifestNumber
    ? (stopDetailsState.details ? stopDetailsState.details[stopNumberIndex] : null)
    : (pickupsState.pickups ? pickupsState.pickups[stopNumberIndex] : null);

  const onOpenPodModal = (consignee: ConsigneeDetails) => {
    setPodModal({
      show: true,
      consignee: consignee,
    });
  };

  const onClosePodModal = () => setPodModal(initialPodModalState);
  const stopAddress = useMemo(() => {
    if (!stopDetailsData) return {
      address: "",
    };
    return {
      address: `${stopDetailsData.address.address1},
      ${stopDetailsData.address.city}, ${stopDetailsData.address.state}`,
      additionalLine: stopDetailsData.address.additionalAddressLine,
    };
  }, [stopDetailsData]);


  const isStopCompleted = stopDetailsData?.status === StopStatus.COMPLETED;

  useEffect(() => {
    if (routeSelectState.activeManifest && (routeSelectState.activeManifest !== manifestNumber)) navigate(ROUTES.route);
  }, [routeSelectState.activeManifest, manifestNumber, navigate]);

  return (
    <div className="xgs-stop-details">
      <SlideInModal
        title={podModal.consignee ? podModal.consignee.name : ""}
        show={podModal.show}
        onClose={onClosePodModal}
      >
        {podModal.consignee && (
          <SignShipment
            show={podModal.show}
            manifestNumber={manifestNumber}
            consignee={podModal.consignee}
            onCloseModal={onClosePodModal}
          />
        )}
      </SlideInModal>
      <Loading
        isLoading={stopDetailsState.requestStarted}
        className="xgs-route-summary__loading"
      />
      {stopDetailsData && (
        <>
          <StopDetailsStickyHeader
            stopDetails={stopDetailsData}
            consigneeIdx={consigneeSectionIdx}
            probillIdx={probillSectionIdx}
          />
          <StopDetailsHeader
            address={stopAddress}
            consigneesCount={stopDetailsData.consignees?.length || 1}
            probillsCount={stopDetailsData.probillsCount || 1}
            itemsCount={stopDetailsData.itemsCount}
          />
            {stopDetailsData.type === "DELIVERY" && <Accordion openByDefault id="consignee-accordion">
              {stopDetailsData.consignees.map(
                (consignee: ConsigneeDetails, consigneeIdx: number) => (
                  <ConsigneeAccordionItem
                    consigneesCount={stopDetailsData.consignees?.length || 1}
                    manifestNumber={manifestNumber}
                    stopNumber={stopNumber || 0}
                    onClickSign={onOpenPodModal}
                    consignee={consignee}
                    id={`consignee-accordion-item-${consigneeIdx}`}
                    key={`consignee-accordion-item-${consigneeIdx}`}
                    isStopCompleted={isStopCompleted}
                  >
                    <Accordion
                      id={`probill-accordion-${consigneeIdx}`}
                      openByDefault
                    >
                      {consignee.probills.map(
                        (probill: ProbillDetails, probillIdx: number) => (
                          <ProbillAccordionItem
                            manifestNumber={manifestNumber}
                            stopNumber={stopNumber || 0}
                            idx={probillIdx}
                            probill={probill}
                            key={probill.probill}
                            consigneeHasException={!!consignee.exception}
                            consigneeDelivered={consignee.delivered}
                            isStopCompleted={isStopCompleted}
                            consigneeIsRefused={!!consignee.exception?.refused}
                          />
                        )
                      )}
                    </Accordion>
                  </ConsigneeAccordionItem>
                )
              )}
            </Accordion>}
            {stopDetailsData.type === "PICKUP" && <Accordion openByDefault>
              <ShipperAccordionItem
                manifestNumber={manifestNumber}
                name={stopDetailsData.consignees[0].name}
                probills={stopDetailsData.consignees[0].probills}                
                stopNumber={stopNumber || 0}
                completed={stopDetailsData.status === "COMPLETED"}
              >
                <Accordion openByDefault>
                  {stopDetailsData.consignees[0].probills.map((probill: ProbillDetails, idx: number) => {
                    return <ProbillAccordionItem
                      manifestNumber={manifestNumber}
                      stopNumber={stopNumber || 0}
                      idx={idx}
                      probill={probill}
                      key={probill.probill}
                      isPickup
                    />
                  })}
                </Accordion>
              </ShipperAccordionItem>
            </Accordion>}
        </>
      )}
      {!stopDetailsData && (
        <div className="xgs-stop-details__no-stop">Stop not found</div>
      )}
    </div>
  );
};
